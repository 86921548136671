.wrap-roadmap {
    width: 99%;
    height: auto;
    
}

.roadmap-container {
    width: 100%;
    margin: 0 auto;
}
.roadmap-title {
    width: 75%;
    margin: 0 auto;
    font-size: 50px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1c4a7d;
}

/**/
.rdm-content-1 {
    margin: 0 0 50px;
    padding-left: 14rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.rdm-content-2 {
    margin: 0 0 50px;
    display: flex;
    flex-direction: row;
}
.rdm-content-1 p , .rdm-content-2 p {
    font-weight: normal;
    font-family: 'Calibri';
    color: #1c4a7d;
}
.rdm-content-1 h3 ,  .rdm-content-2 h3 {
    font-family: 'Calibri';
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0;
}
.rm-content > ul > li{
    font-weight: normal;
    font-family: 'Calibri';
    color: #1c4a7d;
    margin-left: 20px;
}
.rdm-content-row-1 {
    padding: 48px 0;
    display: flex;
}
.rdm-content-row-2 {
    padding: 48px 0;
    display: flex;
}
.rdm-content-row-items {
    padding: 24px;
    display: flex;
    flex-direction: row;
}
.rdm-row-item-1 {
    width: 120px;
    height: 250px;
    display: flex;
    flex-direction: column;
}
.rm-content > p {
    width: 700px;
    font-size: 20px;
    color: #8ea6c0;
}
.rdm-content-img-1 {
    width: 80px; 
    height: 80px;
    margin: 24px 0;
}
.rdm-row-item-2-1 {
    margin-left: 60px;
    width: 700px;
}
.rdm-content-row-2-1 {
    background-image: linear-gradient(to right, #fff 0%, #fdecff 100%);
    margin: 48px 0 0;
    width: 100%;
    height: 350px;
}
.rdm-content-row-2-2 {
    background-image: linear-gradient(to left, #fff 0%, #ffefe6  100%);
    margin: 48px 0 0;
    width: 42%;
    height: 350px;
}
.rdm-content-row-2-3 {
    background-image: linear-gradient(to right, #fff 0%, #bfdeff  100%);
    margin: 48px 0 0;
    width: 100%;
    height: 350px;
}
.rdm-content-row-2-4 {
    background-image: linear-gradient(to left, #fff 0%, #a6ffdd  100%);
    margin: 48px 0 0;
    width: 42%;
    height: 350px;
}
.rdm-content-row-2-5 {
    background-image: linear-gradient(to right, #fff 0%, #bfdeff  100%);
    margin: 48px 0 0;
    width: 100%;
    height: 350px;
}
.rdm-content-row-2-6 {
    background-image: linear-gradient(to left, #fff 0%, #fffdce  100%);
    margin: 48px 0 0;
    width: 42%;
    height: 350px;
}
.rdm-image-1 {
    width: 350px;
    height: 350px;
    margin-right: 80px;
}
.rdm-image-2 {
    width: 350px;
    height: 350px;
    margin-left: 24rem;
}
.rdm-content-row-2-3 > iframe {
    border-radius: 12px;
}
.rdm-btn-item-1 {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    text-decoration: none;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    padding: 15px 41px;
    border-radius: 8px;
    background-color: #1946bd;
    transition: 0.3s;
}
.rdm-btn-item-1:hover {
    color: #fff;
    background-color: #0d6efd;
}



/*** Preset CSS ***/
#mobile_roadmap {
    display: none;
}
.roadmap-version {
    display: flex;
    justify-content: space-evenly;
    margin: 42px auto 0;
    padding: 0 20%;
}
.roadmap-version > * {
    font-family: Cunia;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: center;
    color: #1c4a7d;
}
#roadmap .coming {
    width: 50%;
}
.roadmap-version .coming {
    display: flex;
    justify-content: center;
    color: #b8b8b8;
}
.version-line.selected {
    margin-top: 0.75rem;
    border-bottom: solid 4px #7bc1ff;
    width: 15%;
    transform: translate(275%);
}
.version-wrapper {
    position: relative;
}
.coming-soon-wrap {
    /* position: absolute; */
    /* top: 4%; */
    /* right: 5%; */
    /* width: 12%; */
    height: 54%;
    border-radius: 5px;
    background-color: #ff0505;
    color: #fff;
    font-family: 'Calibri';
    font-size: 12px;
    text-align: center;
    padding: 6px;
    margin-left: 1rem;
}
#roadmap .rm-index {
    font-family: Cunia;
    font-size: 70px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 42px;
    letter-spacing: normal;
    /* color: #cb93d4; */
}
#roadmap .rm-number {
    position: relative;
    margin: 0 40px;
    /* margin-top: 2rem; */
}
#roadmap .right-side {
    padding-left: 48px; 
    padding-right: 10rem;
}
#roadmap .rm-number:before {
    position: absolute;
    left: -11px;
    top: 10px;
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    /* background-color: #cb93d4; */
}
#roadmap .rm-content:before {
    content: "";
    border-left: 2.5px dashed #cb93d4;
    height: 245px;
    position: absolute;
    left: -2px;
    top: 72px;
}
/* #roadmap .rm03 .rm-content:before {
    content: "";
    border-left: 2.5px dashed #cb93d4;
    height: 210px;
    position: absolute;
    left: -2px;
    top: 72px;
} */
#roadmap .rm-title {
    font-family: 'Calibri';
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1c4a7d;
}
#roadmap .rm-description {
    font-family: 'Calibri';
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1c4a7d;
    padding: 2rem 0;
}
#roadmap .rm-content {
    font-family: 'Calibri';
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    color: #8ea6c0;
}
.rm01 .rm-index {
    color: #cb93d4;
}
.rm01 .rm-number:before {
    background-color: #cb93d4;
}
.rm02 .rm-index {
    color: #dbb291;
}
.rm02 .rm-number:before {
    background-color: #dbb291;
}
.rm03 .rm-index {
    color: #7b87e4;
}
.rm03 .rm-number:before {
    background-color: #7b87e4;
}
.rm04 .rm-index {
    color: #20c887;
}
.rm04 .rm-number:before {
    background-color: #20c887;
}
.rm05 .rm-index {
    color: #92a4ff;
}
.rm05 .rm-number:before {
    background-color: #92a4ff;
}
.rm06 .rm-index {
    color: #e2c563;
}
.rm06 .rm-number:before {
    background-color: #e2c563;
}


/**/
@media screen and (max-width: 1536px) {
    .rdm-content-1 {
        width: 100%;
        margin: 0 auto;
        padding-left: 12rem;
    }
    .rdm-row-item-2 {
        width: 550px;
    }
    .rm-content p {
        width: 500px;
        font-size: 18px;
    }
    .rdm-image-2 {
        margin-left: 16rem;
    }
    .rdm-row-item-2-1 {
        width: 500px;
    }
    
}

@media screen and (max-width: 820px) {
    .roadmap-title {
        width: 100%;
        text-align: center;
    }
    .roadmap-version {
        padding: 0;
        justify-content: center;
    }
    .roadmap-version > div  {
        font-size: 16px;
    }
    .roadmap-version p {
        width: 90px;
    }
    .roadmap-title {
        font-size: 24px;
    }
    .roadmap-version .coming {
        justify-content: center;
    }
    .coming {
        /* width: 300px !important; */
        margin-left: 90px;
    }
    .coming-soon-wrap {
        margin-left: 90px;
        padding: 12px 24px 24px;
    }

    /**/
    .rdm-content-1 {
        flex-direction: column-reverse;
        padding: 0;
        margin-bottom: 0;
    }
    .rdm-content-2 {
        flex-direction: column;
        margin-bottom: 0;
    }

    /* 1 */
    .rdm-content-row-1 {
        flex-direction: column;
        padding: 24px 0;
    }
    .rdm-content-row-2 {
        flex-direction: column;
        padding: 24px 0;
    }
    .rdm-row-item-1  {
        width: 100%;
        height: auto;
        flex-direction: row;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
    }
    .rdm-content-img-1 {
        width: 48px;
        height: 48px;
        margin: 0 12px;
    }
    .rdm-row-item-1 > h2 {
        font-size: 48px !important;
        margin-left: 60px;
    }
    .rdm-row-item-2 {
        width: 100%;
        margin: 0;
    }
    .rm-title > h3 {
        font-size: 16px;
        text-align: center;
    }
    .rm-description {
        margin: 0 auto;
        padding: 12px 0 !important;
    }
    .rm-description > p {
        font-size: 13px;
        text-align: center;
    }
    .rm-content {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
    }
    .rm-content p {
        width: 90%;
        font-size: 13px;
        margin: 0 auto;
        text-align: center;
        color: #8ea6c0;
    }
    .rm-content ul {
        font-size: 13px;
        margin: 0 auto;
    }
    .rdm-btn-item-1 {
        margin: 0 auto;
    }
    .rdm-content-row-items {
        flex-direction: column;
        padding: 0;
    }
    .rdm-row-item-2-1 {
        width: 90%;
        margin: 0 auto;
        padding: 12px 0 0;
    }
    .rdm-content-row-2-1 {
        background-image: linear-gradient(to right, #fff 35%, #fdecff 100%);
        height: 160px;
        width: 100%;
        text-align: center;
    }
    .rdm-content-row-2-2 {
        background-image: linear-gradient(to left, #fff 35%, #ffefe6 100%);
        height: 160px;
        width: 100%;
        text-align: center;
        margin: 12px 0 0;
    }
    .rdm-content-row-2-3 {
        background-image: linear-gradient(to right, #fff 35%, #fdecff 100%);
        height: 160px;
        width: 100%;
        text-align: center;
        margin: 12px 0 0;
    }
    .rdm-content-row-2-4 {
        background-image: linear-gradient(to left, #fff 35%, #a6ffdd 100%);
        height: 160px;
        width: 100%;
        text-align: center;
        margin: 12px 0 0;
    }
    .rdm-content-row-2-5 {
        background-image: linear-gradient(to right, #fff 35%, #bfdeff 100%);
        height: 160px;
        width: 100%;
        text-align: center;
        margin: 12px 0 0;
    }
    .rdm-content-row-2-6 {
        background-image: linear-gradient(to left, #fff 35%, #fffdce 100%);
        height: 160px;
        width: 100%;
        text-align: center;
        margin: 12px 0 0;
    }

    .rdm-image-1 {
        width: 160px;
        height: 160px;
        text-align: center;
    }
    .rdm-image-2 {
        width: 160px;
        height: 160px;
        text-align: center;
        margin: 0 auto;
    }
    .iframe-vid-3 {
        width: 270px;
        height: 160px;
    }

    /* Preset CSS */
    #roadmap .rm-number {
        margin: 0;
    }
    #roadmap .rm-number:before {
        display: none;
    } /* circle */
    #roadmap .rm-content:before {
        display: none;
    } /* dotted line */
    .version-line.selected {
        width: 75%;
        transform: translate(16.5%);
    }
}

@media screen and (max-width: 576px) {
    .roadmap-version {
        padding: unset;
        display: flex;
        margin-top: 3rem;
        padding: 0 15%;
        max-width: 80%;
    }
    #roadmap .coming {
        width: 100%;
        margin-left: 50px;
    }
    .coming-soon-wrap {
        margin-left: 20px;
        padding: 8px;
        width: 56px;
        height: 40px;
    }
    .rdm-image-1 {
        margin-right: 20px;
    }
    .rdm-image-2 {
        margin-left: 20px;
    }
}