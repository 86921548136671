.wrap-team {
    width: 100%;
    height: auto;
    margin-bottom: 60px;
}

/**/
.pre-team-bg {
    width: 100%;
}

.team-container {
    width: 75%;
    max-width: 1440px;
    margin: 2rem auto;
}
.team-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
.team-displaypic {
    width: 200px;
    border-radius: 100px;
}
.team-alias {
    font-family: 'Calibri';
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #8ea6c0;
    margin: 12px 0;
}
.twitter-name {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* align-items: center; */
}
.twitter-name p {
    font-family: 'Calibri';
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1c4a7d;
    margin-bottom: 0;
}
.twitter-name > a {
    text-decoration: none;
    height: 15px;
}
.team-twitter-i > i{
    font-size: 15px;
    color:#00acee;
    margin-bottom: 20px;
    padding-left: 8px;
    vertical-align: baseline;
}
.role-title {
    font-family: 'Calibri';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #8ea6c0;
    margin: 12px 0;
}




@media screen and (max-width: 820px) {
    .team-content {
        justify-content: space-around;
    }
    .team-displaypic {
        width: 120px;
    }
}

@media screen and (max-width: 576px) {
    .team-content {
        justify-content: center;
    }
    .team-displaypic {
        width: 80px;
        margin: 0 24px;
    }
}