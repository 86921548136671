
.wrap-game-center-hero {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),  url('../images/BG-53.png') ;
    margin-top: -13rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 1385px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
  }

.game-center-header-container {
    width: 75%;
    max-width: 1440px;
    margin: -56rem auto 0;
    display: flex;
    align-items: center;
}
.game-center-header {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    margin: 0;
    padding-top: 40px;
}
.game-center-header > h2 {
    font-family: Cunia;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    padding-right: 30px;
}
.game-center-header-member {  
    color: #8ea6c0;
}
.game-center-header-text {  
    color: #1c4a7d;
}
.game-center-header > i {
    padding-right: 30px;
    font-size: 16px;  
    color: #1c4a7d; 
}
.game-center-header-title {
    /* margin-top: -55%; */
    position: absolute;
    top: 22%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: unset;
    width: 100%;
}

.game-center-header-title .title {
    padding: 2rem 0 2.5rem;
    font-family: Cunia;
    font-size: 60px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1c4a7d;
}

.game-center-pictures {
    padding: 2% 12% 12%;
    display: flex;
    justify-content: space-between;
}

.game-center-pictures .img-wrapper {
    padding: 0 2%;
}

@media screen and (max-width: 820px) {
    .game-center-pictures {
        /* padding: 0 12% 12%; */
        display: unset;
        /* justify-content: space-between; */
    }
}

@media screen and (max-width: 576px) {

    .game-center-header-title {
        /* margin-top: -180%; */
        top: 30%;
    }
    .game-center-header-title .title {
        padding-top: unset;
        padding-bottom: unset;
        font-size: 24px;
    }
    .wrap-game-center-hero {
        height: 800px;
        background-size: cover;
        background-position: 50% 0%;
    }

    .game-center-header-container {
        display: none;
    }

    .game-center-pictures .img-wrapper {
        padding: 0 5%;
        margin-bottom: 5%;
    }
}