
.wrap-hero {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)),  url('../images/BG-01.png') ;
  margin-top: -13rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 1440px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
/**/
.hero-container {
  width: 75%;
  max-width: 1440px;
  margin: 0 auto;
  
}
.hero-heading {
  height: auto;
  margin: 0 auto;
}
.hero-header-1 {
  color: #fff;
  font-size: 50px;
}
.hero-header-2 {
  color: #fff;
  font-size: 180px;
}
.hero-header-3 {
  color: #fff;
  font-size: 50px;
}

.hero-btns {
  height: auto;
  margin: 60px auto;
}
.hero-btn-item-1 {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  text-decoration: none;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  padding: 15px 41px;
  border-radius: 8px;
  background-color: #1946bd;
  transition: 0.3s;
}
.hero-btn-item-1:hover {
  background-color: #0d6efd;
  color: #fff;
}
.hero-btn-item-2 {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  text-decoration: none;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  margin-left: 24px;
  padding: 15px 41px;
  border: 1px solid #6c757d;
  border-radius: 8px;
  background-color: #222d4b;
  transition: 0.3s;
}
.hero-btn-item-2:hover {
  background-color: #6c757d;
  color: #fff;
}
.heroschedule-btn:hover {
  cursor: pointer;
  transition: all 0.4s ease;
  box-shadow: 0 4px 8px rgba(27, 27, 27, 0.5);
}



@media screen and (max-width: 820px) {
  .wrap-hero {
    height: 700px;
    background-position: 100% 0%;
    background-repeat: no-repeat;
    background-attachment: scroll;
    margin: 0;
    background-size: 100% auto;
  }
  .hero-heading {
    margin-top: 550px;
  }
  .hero-heading > h1 {
    color: #1c4a7d;
    text-align: center;
  }
  .hero-header-1 {
    font-size: 20px;
  }
  .hero-header-2 {
    font-size: 80px;
  }
  .hero-header-3 {
    font-size: 24px;
  }
  .hero-btns {
    text-align: center;
  }
}

@media screen and (max-width: 576px) {
  .wrap-hero {
    height: 700px;
    background-size: 150% auto;
  }
  .hero-container {
    width: 90%;
  }
  .hero-heading {
    margin-top: 250px;
  }
  .hero-btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .hero-btns > a {
    font-size: 13px;
  }
  .hero-btn-item-1 {
    padding: 15px 24px;
  }
  .hero-btn-item-2 {
    padding: 15px 24px;
  }
}


