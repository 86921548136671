
html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Cunia';
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'Calibri';
  src: local('Calibri'), url(./fonts/CALIBRI_0.TTF) format('TTF');
  src: url(./fonts/CALIBRIB_0.TTF) format('TTF');
}

@font-face {
  font-family: 'Cunia';
  src: local('Cunia'), url(./fonts/Cunia.otf) format('opentype');
  src: url(./fonts/Cunia.otf) format('opentype');
}

@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot");
  src: url("fonts/icomoon.eot?#iefix") format("embedded-opentype"), url("fonts/icomoon.woff")
      format("woff"), url("fonts/icomoon.ttf") format("truetype"), url("fonts/icomoon.svg#icomoon")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "icomoon";
  content: attr(data-icon);
  /* speak: none; */
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;

}



.wrap-storyline-maincontainer:target {
  padding-top: 100px;
  margin-top: -50px;
}

.wrap-roadmap:target {
  padding-top: 100px;
  margin-top: -50px;
}





