
.wrap-pet-hero {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)),  url('../images/BG-27.png') ;
  margin-top: -13rem;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  width: 100%;
  height: 1385px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.pet-header-container {
  width: 75%;
  max-width: 1440px;
  margin: -56rem auto 0;
  display: flex;
  align-items: center;
}
.pet-header {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding-top: 40px;
}
.pet-header > h2 {
  font-family: Cunia;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  padding-right: 30px;
}
.pet-header-member {  
  color: #8ea6c0;
}
.pet-header-text {  
  color: #1c4a7d;
}
.pet-header > i {
  padding-right: 30px;
  font-size: 16px;  
  color: #1c4a7d; 
}
/*  */
#mint_section {
  /* margin-top: 10.5%; */
  width: 100%;
}
#akfc_raffle .metamask {
  margin-top: 2.5rem;
}
.header-title {
  margin-top: -12%;
  margin-bottom: 5%;
  text-align: center;
}
.header-title #logo {
  width: 210px;
}
.header-title .title {
  padding: 2rem 0 2.5rem;
  font-family: Cunia;
  font-size: 60px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c4a7d;
}

@media screen and (max-width: 820px) {
  .wrap-pet-hero {
    height: 700px;
    background-position: 100% 0%;
    background-repeat: no-repeat;
    background-attachment: scroll;
    margin: 0;
    background-size: 100% auto;
  }
  .pet-header-container {
    margin: -40rem auto 0;
  }
}
  
@media screen and (max-width: 576px) {
  .wrap-pet-hero {
    height: 500px;
    background-size: 175% auto;
    background-position: 50% -78%;
  }
  .pet-header-container {
    display: none;
  }
  .header-title {
    margin-top: -48%;
    margin-bottom: 5%;
    text-align: center;
  }
  .header-title #logo {
    width: 120px;
  }
  .header-title .title {
    font-size: 24px;
  }
  
}