
.wrap-storyline {
    width: 100%;
}
.pre-story {
    /* width: 100%; */
    /* height: auto; */
    display: none;
}
.pre-story-desktop {
    width: 100%;
    height: auto;
}
#storyline {
    margin-top: -13rem;
    background-image: url('../images/BG-06.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 10rem;
    padding-bottom: 10rem;
}
.storyline-container {
    width: 75%;
    max-width: 1440px;
    position: relative;
    border-radius: 16px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin: 0 auto;
    padding: 3rem 1rem 4rem;
}
.storyline-container .monkey {
    position: absolute;
    top: -75px;
    left: 50%;
    transform: translate(-50%, -50%);
}
.storyline-container .monkey > img {
    width: 600px;
}
.storyline-content {
    padding: 0 3rem;
    text-align: center;
}
.storyline-heading {
    font-size: 50px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1c4a7d;
    padding: 0 0 16px;
}
.storyline-content .image-slider {
    padding: 2rem 0 3rem;
}
.storyline-content .image-slider > img {
    width: 100%;;
}
.storyline-desc {
    width: 90%;
    margin: 0 auto 24px;
}
.storyline-desc > p {
    font-family: 'Calibri', sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #8ea6c0;
    padding: 0 24px;
}
.storyline-linkbtn {
    padding: 24px 0;
}
.storyline-linktree-btn {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    text-decoration: none;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    padding: 15px 41px;
    border-radius: 8px;
    background-color: #1946bd;
    transition: 0.3s;
    margin-top: 100px;
}
.storyline-linktree-btn:hover {
    background-color: #0d6efd;
    color: #fff;
}



@media screen and (max-width: 820px) {
    .pre-story {
        display: block;
        /* margin-top: -5rem; */
        position: relative;
        min-height: 450px;
        background-image: url('../images/BG-02.png');
        background-position: 100% 0%;
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-size: cover;
    }
    .pre-story-desktop {
        display: none;
    }
    .storyline-maincontainer {
        margin-top: -12rem !important;
    }
    .storyline-container .monkey {
        left: 50%;
        transform: translate(-50%, -50%);
        top: -20px;
    }
    .storyline-container .monkey img {
        width: 188px;
    }
    .storyline-container {
        width: 80%;
        padding: 0;
    }
    .storyline-content > .image-slider {
        padding-top: 0;
    }
    .storyline-heading {
        font-size: 25px;
        padding: 24px 0 0;
    }
    .storyline-content {
        width: 100%;
        padding: 24px;
    }
    .storyline-desc {
        width: 100%;
    }
}

@media screen and (max-width: 576px) {
    .hero-container {
        padding-top: 50%;
    }
    .storyline-container {
        width: 90%;
    }
    .storyline-content > .image-slider {
        overflow: auto;
        padding-bottom: 24px;
    }
    .storyline-content > .image-slider > img {
        width: unset;
        height: 150px;
    }
    .storyline-desc p {
        padding: 0;
        font-size: 13px;
    }
}