.wrap-merchcart {
    width: 100%;
    height: 100vh;
    margin: 0;
    background-color: #f8f8f8;
}

.merchcart-header-container {
    width: 75%;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.merchcart-header {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    margin: 0;
    padding-top: 40px;
    /* align-items: center; */
}
.merchcart-header > h2 {
    font-family: Cunia;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    padding-right: 30px;
}
.merchcart-header-member {  
    color: #8ea6c0;
}
.merchcart-header-merch {  
    color: #1c4a7d;
}
.merchcart-header > i {
    padding-right: 30px;
    font-size: 16px;  
    color: #1c4a7d; 
}
.close-merchcart > a {
    text-decoration: none;
}
.close-merchcart  i {
    font-size: 36px;  
    color: #afafaf; 
}
/* Cart items */
.merchcart-container {
    width: 900px;
    margin: 50px auto 0;
}
.total-cart-items {
    /* padding: 12px 0; */
    border-bottom: solid 1px #e4e4e4;
}
.total-cart-items > p {
    font-family: 'Calibri';
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #afafaf;
    padding-left: 150px;
}
.merchcart-content {
    width: 100%;
}
.merchcart-list {
    display: flex;
    flex-direction: row;
    padding: 20px 0;
    align-items: center;
    border-bottom: solid 1px #e4e4e4;
}
.merchcart-list > input {
    cursor: pointer;
    margin-right: 20px;
}
.merchcart-item-img {
    width: 90px;
    margin-right: 24px;
}
.merchcart-item-name {
    font-family: 'Calibri';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4d4d4d;
}
.merchcart-item-price, .merchcart-quantity {
    font-family: 'Calibri';
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4d4d4d;
}
.merchcart-quantity {
    margin-left: auto;
}

/* Total Price Summary */

.merchcart-price-sum {
    width: 900px;
    background-color: #fff;
    margin: 50px auto 0;
    padding: 24px;
}
.merchcart-summary {
    display: flex;
    justify-content: space-between;
}
.merchcart-sum-text {
    font-family: 'Calibri';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4d4d4d;
}
.merchcart-sum-price {
    font-family: 'Calibri';
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4d4d4d;
}
.merchcart-final-purc { 
    text-align: center;
    margin: 20px auto ;
}
.merchcart-final-purc > a {
    margin: 0 auto;
    width: 300px;
    color: #fff;
    padding: 16px 40px;
    font-size: 14px;
    border: none;
    border-radius: 10px;
    background-color: #1946bd;
    text-decoration: none;
    cursor: pointer;
    transition: 0.3s;
}
.merchcart-final-purc > a:hover{
    background-color: #0d6efd;
    color: #fff;
}

.merchcart-quantity .delete-item {
    border-radius: 8px;
    border: solid 1px #ff4c4c;
    color: #ff4c4c;
    font-family: Calibri;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 40px;
}
.merchcart-quantity .select-quantity {
    background-color: transparent;
    border-radius: 8px;
    border: solid 1px #1946bd !important;
    color: #1946bd;
    font-size: 14px;
    font-weight: bold;
    width: 150px !important;
    padding-right: 3.5rem;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAB3UlEQVRIie2PvWtTcRSGn/PLRztIBqE2CSLuDk5uFpNL7m2suoiDH4Mo6GQnBf8FoZOI0M1BVBARFCG56c2HUEQQBwcLBQeD6U38oEIo0tj0HocmUiVNQ7PmXQ6c877POQdGGmlYScIpVoFDQFPQc34hszAMMOl4tiLPgBhK1UgQXAKaQEyRV4ls6exe4ZOOd1qRl0AMWFOjV4zv2YsgFsIPIEqgT+OOd3kPl58PIc+BceBnEBi74WbKBqBesN6r0RMKPhAS5EHSKc4OCk84peuKPFKIAN+MSPqrl34LYLqmRi6zZIxYQA0Qhbtxp3hr18unizdA5wEjaF1FrBXX+tCdm+1mP28tE+a4widABObidvFOH/htVe4BglJth2Sq4Voft3ukV3BiphwPtwMPOAIgIvd9Nz279RiAStIuzalwsxNZNiGTWcmla/+zei4AmHTcA4ZwATi6xeRhfcNcJfU6SLyZmgeudfpLYTbtLwuO34uz4wKAg9P5/ZtBJI9wrON+0oFe6NR3IbORrbnZ1Z0YfRcATKTK+yLR4IWC9W9SFltm/dRqbqbZL2/6DQG+V9JrjP06AxT+NlUq7Zac3A0OA3zQ1eFUebwVDR4DjP02Fz9X0uuDZkcaaTj9AbOdpNqaQGwgAAAAAElFTkSuQmCC');
    background-position: right 2rem center;
}

@media screen and (max-width: 820px) {
    .wrap-merchcart {
        height: 70vh;
        position: relative; 
    }
    .merchcart-header-container {
        width: 90%;
    }
    .merchcart-container {
        width: 90%;
        margin: 0 auto;
    }

    .merchcart-price-sum {
        width: 100%;
        position: absolute; 
        bottom: 0;
    }
}

@media screen and (max-width: 576px) {
    .wrap-merchcart {
        height: 700px ;
    }
    .merchcart-price-sum {
        width: 100%; 
    }
    .merchcart-list {
        flex-wrap: wrap;
    }
    .merchcart-quantity {
        margin-top: 10px;
        flex-basis: 100%;
    }
}