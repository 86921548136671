.wrap-akfc-hero {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)),  url('../images/BG-30.png') ;
  margin-top: -13rem;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  width: 100%;
  height: 1385px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
#akfc_raffle .raffle-now {
  font-family: Cunia;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  padding: 15px 90px;
  border-radius: 8px;
  background-color: #1946bd;
  border-color: #1946bd;
  /* pointer-events: none; */
}
#akfc_raffle .raffle-now > a {
  text-decoration: none;
  color: #fff;
}
#akfc_raffle .raffle-now:hover {
  border-color:#0d6efd;
  background-color: #0d6efd;
}
.header-title {
  margin-top: -12%;
  margin-bottom: 5%;
  text-align: center;
}
.header-title #logo {
  width: 210px;
}
.header-title .title {
  padding: 2rem 0 2.5rem;
  font-family: Cunia;
  font-size: 60px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c4a7d;
}


/* inter meta fc  */
.intermeta_img {
  width: 100%;
  text-align: center;
  margin: -300px 0 60px;
}

.intermeta_logo {
  width: 250px;
  height: auto;
  display: flex;
  margin: 0 auto;
}
.col-md-4 > .mint-title {
  margin-bottom: 30px;
}

.text-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-center > a {
  text-decoration: none;
}

.disc-btn {
  color: #fff;
  background: #7289da;
  padding: 9px 28px 11px;
  border: none;
  border-radius: 8px;
  margin-right: 20px;
  display: flex; 
  align-items: center;
}
.disc-btn:hover {
  background: #3d5fd9;
}

.twitter-btn {
  color: #fff;
  background: #1DA1F2;
  padding: 16px 28px;
  border: none;
  border-radius: 8px;
  margin-right: 20px;
  display: flex; 
  align-items: center;
}
.twitter-btn:hover {
  background: #0095f1;
}

.disc-btn > i {
  font-size: 24px;
  margin-right: 14px;
}

.twitter-btn > i {
  font-size: 24px;
  margin-right: 14px;
}








@media screen and (max-width: 820px) {
  .header-title #logo {
    width: 120px;
  }
  .header-title .title {
    font-size: 24px;
  }
  #akfc_raffle .raffle-now {
    padding: 15px 60px;
  }
  .wrap-akfc-hero {
    background-image: url('../images/BG-30_.png');
    height: 700px;
    background-position: 100% 0%;
    background-repeat: no-repeat;
    background-attachment: scroll;
    margin: 0;
    background-size: 100% auto;
  }



  /* intermeta  */
  .intermeta_img {
    width: 100%;
    text-align: center;
    margin: 60px 0 30px;
  }

  .intermeta_img > img {
    width: 120px;
  }

  .mint-title > h2 {
    font-size: 24px;
    margin-bottom: 30px;
  }

}
  
@media screen and (max-width: 576px) {
  .wrap-akfc-hero {
    height: 500px;
    background-size: 175% auto;
    background-position: 50% -78%;
  }
  .header-title {
    margin-top: -48%;
    margin-bottom: 5%;
    text-align: center;
    padding: 0 5%;
  }


  
  .mint-title > h2 {
    font-size: 24px;
  }
  
  .text-center {
    justify-content: space-around;
  }

  .disc-btn, .twitter-btn {
    margin: 0 auto;
  }
}
