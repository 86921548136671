
.wrap-footer {
    font-family: 'Calibri', sans-serif;
    background: #052A53;
    width: 100%;
}
/**/
.footer-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 48px 60px;
}
.footer-content-1 {
    margin-right: auto;
}
.footer-content-2 {
    margin-left: auto;
    display: flex;
    flex-direction: column;
}

/* footer-content-1 */
.footer-apk-img {
    height: 60px;
    margin-bottom: 24px;
}
.footer-text {
    margin-bottom: 24px;
}
.footer-text > h4 {
    font-family: 'Calibri', sans-serif;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
}
.footer-copyright > p {
    font-family: 'Calibri', sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8ea6c0;
}
.footer-icons {
    text-align: right; 
    padding: 1rem; 
    margin-top: 4rem;
}
/* footer-content-2 */
.footer-menu {
    display: flex;
    margin: 0 0 60px;
}
.footer-links {
    text-decoration: none;
    list-style-type: none;
}
.footer-menu > ul {
    display: flex;
    align-items: center;
    height: 36px;
    text-decoration: none;
}
.footer-item > a {
    font-family: 'Calibri';
    text-decoration: none;
    padding: 0px 18px;
    font-size: 17px;
    cursor: pointer;
    color: #fff;
    transition: 0.2s;
}
.footer-item > a:hover {
    color: #3a7fed;
}
/**/
.footer-sc-icons {
    display: flex;
    align-items: center;
    margin-left: auto;
}
.footer-icon-link {
    margin: 0 28px;
    color: #3a7fed;
    font-size: 36px;
}
.footer-sc-icons .discord {
    vertical-align: baseline !important;
}

@media screen and (max-width: 820px) {
    .footer-container {
        flex-direction: column;
        text-align: center;
        padding: 24px 0 36px;
    }
    .footer-apk-img {
        height: 40px;
    }
    .footer-text {
        font-size: 18px;
    }
    .footer-copyright {
        font-size: 12px;
    }
    .footer-menu {
        display: none;
    }
    .footer-content-1 {
        margin: 0 auto;
        padding: 16px 0;
    }
    .footer-content-2 {
        margin: 0 auto;
        text-align: center;
        padding: 16px 0;
    }
    .footer-icon-link {
        font-size: 25px;
    }
    .footer-sc-icons {
        margin-left: 0;
        text-align: center;
    }
    
}