

.wrap-charity {
    background-image: linear-gradient(to bottom, #fff, #a5dafd 20%, #fff);
    width: 100%;
    height: auto;
}


.wrap-charity-hero {
    /* background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),  url('../images/BG-53.png') ; */
    margin-top: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    text-transform: uppercase;
  }

.charity-header-container {
    width: 75%;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    align-items: center;
}
.charity-header {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    margin: 0;
    padding-top: 40px;
}
.charity-header > h2 {
    font-family: Cunia;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    padding-right: 30px;
}
.charity-header-member {  
    color: #8ea6c0;
}
.charity-header-text {  
    color: #1c4a7d;
}
.charity-header > i {
    padding-right: 30px;
    font-size: 16px;  
    color: #1c4a7d; 
}

/* content  */
.charity-container { 
    width: 60%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 0 120px;
    margin-top: 2.5rem;
}

.charity-container-header > h1 {
    font-size: 60px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1c4a7d;
    margin-bottom: 40px;
}

.charity-content-1 > h2 {
    font-size: 35px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1c4a7d;
    margin-bottom: 40px;
}

.charity-content-1 > p {
    font-family: 'Calibri';
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #1c4a7d;
    margin-bottom: 30px;
    text-transform: initial;
}

.charity-content-1 > figure {
    text-align: center;
    margin-bottom: 30px;
}

.charity-content-1 > figure > img {
    width: 100%;
}

.char-yt-container {
    width: 100%;
    margin: 0 auto 30px;
}

.char-yt-container > iframe {
    /* width: 800px;
    height: 450px; */
    width: 100%;
    height: 500px;
    margin: 0 auto ;
}

.charity-content-2 {
    margin: 30px auto;
}

.charity-content-2 a {
    font-family: 'Calibri';
}

.charity-content-2 > div {
    margin-bottom: 30px;
}

.charity-content-2 > div > h3 {
    font-family: 'Calibri';
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #1c4a7d;
    margin-bottom: 5px;
    text-transform: initial;
}

.charity-content-2 > div > p {
    font-family: 'Calibri';
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #1c4a7d;
    margin-bottom: 30px;
    text-transform: initial;
}

.char-about-contact,
.char-about-contact > a {
    font-family: 'Calibri';
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #1c4a7d;
    margin-bottom: 5px;
    text-transform: initial;
}

.view-tnc {
    width: 300px;
    margin: 0 auto;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    text-decoration: none;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    padding: 15px 30px;
    border-radius: 8px;
    background-color: #1946bd;
    transition: 0.3s;
    /* margin-top: 100px; */
    cursor: pointer;
}

.view-tnc:hover {
    background-color: #0d6efd;
    color: #fff;
}

.tnc {
    text-align: center;
    font-family: 'Calibri';
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #1c4a7d;
    margin-bottom: 5px;
    text-transform: initial;
}

.tnc > span {
    font-family: 'Calibri';
    text-decoration: underline;
    cursor: pointer;
}

.six-characters {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
}

.six-characters img {
    width: 100%;
}

.tnc_modal .modal-dialog {
    max-width: 600px;
}

.tnc_modal .modal-title {
    margin-left: 28%;
    font-size: 20px;
    font-family: 'Calibri';
}

.tnc_modal .modal-content {
    width: unset !important;
}

.tnc_modal .modal-body {
    padding: 1rem !important;
}

.tnc_modal h3 {
    font-family: 'Calibri';
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #1c4a7d;
    margin-bottom: 5px;
    text-transform: initial;
}

.tnc_modal li {
    font-family: 'Calibri';
    font-size: 18px;
    color: #1c4a7d;
    margin-bottom: 1.5rem;
}

.tnc_modal li a {
    font-family: 'Calibri';
    font-size: 18px;
}

.quiz .subtitle {
    text-align: center;
    margin-bottom: 20px;
}
.quiz .hint  {
    text-align: center;
    color: #1c4a7d;
    font-size: 20px;
    font-weight: 600;
}
.quiz .hint a {
    font-family: 'Calibri';
}
.quiz .question {
    font-family: 'Calibri';
    font-size: 24px;
    font-weight: 600;
    color: #1c4a7d;
}
.quiz .answers {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
}
.quiz .answer {
    cursor: pointer;
    text-align: center;
    border: 2px solid #2744ba;
    border-radius: 20px;
    padding: 25px 0;
}
.quiz .answer.selected {
    background-color: #2744ba;
    color: #fff;
}
.quiz .answer.selected.wrong {
    border: 2px solid #fb4a51;
    background-color: #fb4a51;
}
.quiz .answer.selected.correct {
    border: 2px solid #87b754;
    background-color: #87b754;
}

.quiz .message-correct {
    font-family: 'Calibri';
    color: #87b754;
}
.quiz .message-wrong {
    font-family: 'Calibri';
    color: #fb4a51;
}

.submit-wrapper {
    margin-top: 5rem;
    text-align: center;
}
.submit-wrapper .btn-primary {
    background-color: #2744ba;
    font-size: 18px;
    padding: 15px 50px;
    border-radius: 15px;
}
.reward_modal .modal-title {
    margin-left: 40%;
    font-size: 20px;
    font-family: 'Calibri';
}
.reward_modal .modal-body {
    padding: 0 3rem;
}
.reward_modal .modal-content {
    width: unset !important;
}
.reward_modal h2 {
    margin-top: 1.5rem;
    text-align: center;
    font-family: 'Calibri';
    color: #1c4a7d;
    font-weight: 600;
}

.redeem .inputbox {
    font-family: 'Calibri';
    align-items: center;
    background-color: #fff;
    padding: 10px 30px;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
}

.redeem .inputbox input {
    width: 60%;
    text-align: right;
    border: unset;
}
.redeem .inputbox input:focus {
    outline: none;
  }

.redeem .inputbox > div {
    font-family: 'Calibri';
    color: #1c4a7d;
    font-weight: 600;
}
.redeem .hint  {
    text-align: center;
    color: #1c4a7d;
    font-size: 20px;
    font-weight: 600;
}
.redeem .submit-wrapper {
    margin-top: 2.5rem;
}
.redeem_modal .modal-title {
    margin-left: 30%;
    font-size: 20px;
    font-family: 'Calibri';
}
.redeem_modal .modal-body {
    text-align: center;
}
.redeem_modal h2,h5 {
    font-family: 'Calibri';
    color: #23497b;
    font-weight: 600;
}
.redeem_modal .modal-content {
    width: unset !important;
}
@media screen and (max-width: 900px) {

    .six-characters {
        grid-template-columns: repeat(3, 1fr);
    }

    .charity-container {
        width: 80%;
    }

    .charity-content-1 > h2 {
        font-size: 30px;
    }

    .charity-content-1 > figure > img {
        width: 100%;
    }
    
    .char-yt-container > iframe {
        height: 380px;
    }

}


@media screen and (max-width: 576px) {

    .redeem .inputbox {
        display: block;
        justify-content: unset;
        padding: 5px 10px;
    }
    .redeem .inputbox input {
        width: 100%;
        text-align: left;
        border: unset;
    }

    .wrap-charity {
        background-image: linear-gradient(to bottom, #fff, #a5dafd 10%, #fff);
        width: 100%;
        height: auto;
    }

    .wrap-charity-hero {
        display: none;
    }

    .charity-container-header > h1 {
        font-size: 24px;
        margin: 48px auto;
    }

    .charity-container {
        width: 90%;
    }

    .charity-content-1 > h2 {
        font-size: 20px;  
        line-height: normal;
    }

    .charity-content-1 > p {
        font-size: 16px;
    }
    
    .char-yt-container > iframe {
        height: 280px;
    }

    .charity-content-2 > div > p {
        font-size: 16px;
    }

    .char-about-contact, .char-about-contact > a {
        font-size: 16px;
    }

}

