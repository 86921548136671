

.wrap-faq {
    width: 100%;
    /* height: 900px; */
    margin-top: -90px;
    padding: 5rem 0 7em;
    background-image: linear-gradient(to bottom, #fcfcfc, #fff);
}

.faq-container {
    width: 75%;
    max-width: 1440px;
    margin: 0 auto;
}
.faq-header > h1 {
    font-size: 50px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1c4a7d;
    margin: 0 0 30px;
}

.accordion-container-items {
    display: flex;
}

#akc_accordion .akc-accordion {
    background: #fff;
    margin-bottom: 20px;
    padding-bottom: 1rem;
    border-radius: 15px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}
#akc_accordion .akc-accordion .accordion-button:not(.collapsed) {
    color: #1c4a7d;
    background-color: #fff;
    box-shadow: unset;
}
#akc_accordion .akc-accordion .accordion-button {
    padding: 2rem 2rem 1rem;
    border-radius: 15px;
    color: #1c4a7d;
    font-weight: 600;
    font-size: 20px;
    font-family: 'Calibri';
}
#akc_accordion .akc-accordion .accordion-button:focus {
    /* border-radius: white; */
    box-shadow: unset;
}
#akc_accordion .akc-accordion .accordion-body {
    padding: 0 2rem 1rem;
    color: #8ea6c0;
    font-size: 20px;
    font-family: 'Calibri';
}

@media (min-width: 1200px){
    h2, .h2 {
        font-size: 2rem;
    }

}

@media screen and (max-width: 820px) {
    .wrap-faq {
        /* height: 1200px; */
        margin-top: -50px;
    }
    .faq-header > h1 {
        text-align: center;
        font-size: 24px;
    }
    .faq-container {
        width: 80%;
    }

    #akc_accordion .akc-accordion {
        padding-bottom: 1.5rem;
    }
    #akc_accordion .akc-accordion .accordion-button {
        padding: 1.5rem 1.5rem 0;
        font-size: 14px;
    }
    #akc_accordion .akc-accordion .accordion-button::after {
        width: .75rem;
        height: .75rem;
        background-size: .75rem;
    }
    #akc_accordion .akc-accordion .accordion-body {
        padding: 0.5rem 1.5rem 0;
        font-size: 12px;
    }
}
@media screen and (max-width: 576px) {
    .wrap-faq {
        /* height: 140vh; */
        padding-bottom: 1rem;
    }
    .faq-container {
        width: 90%;
    }
}