.navbar1 {
    background: #fff;
    width: 100%;
    height: 110px;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    z-index: 1;
}
/**/
.navbar-logo { 
    display: flex;
    width: 100px;
    color: #fff;
    margin: 60px 30px 0 50px;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
}

.nav-menu {
    display: flex;
    margin: 0;
}
ul.nav-menu {
    padding-left: 0;
}
.nav-mobile-header {
    display: none;
}
.nav-item {
    display: flex;
    align-items: center;
    height: 80px;
}

.nav-links {
    text-decoration: none;
    padding: 8px 24px;
    font-size: 16px;
    cursor: pointer;
}

.fa-bars {
    color: #1946bd;
    font-size: 20px;
}

.nav-links-mobile {
    display: none;
}
  
.menu-icon {
    display: none;
}

.navbar-container-mobile {
    display: none;
}

/* dropdown */
.dropdown-menu1 {
    background: #fff;
    width: 250px;
    position: absolute;
    top: 0;
    margin-top: 90px;
    list-style: none;
    text-align: start;
    border-radius: 16px;
    padding-bottom: 24px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    -webkit-filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.05));
    filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.05));
}
ul.dropdown-menu1 {
    padding-left: 0;
}
.dropdown-menu1 li {
    cursor: pointer;
    color: #1946bd;
    border-bottom: 1px solid #e8e8e8;
    padding: 16px;
}

.dropdown-menu1.clicked {
    display: none;
}
.dropdown-link-items {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.dropdown-link {
    background: transparent;
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: #1946bd;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.dropdown-link > span {
    display: none;
}
.dropdown-link-coming {
    background: transparent;
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: #c3c3c3;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.dropdown-link-coming > i {
    display: none;
}
.dd-coming-soon {
    padding: 3px 8px;
    background-color: #ff0505;
    border-radius: 5px;
    font-family: Calibri;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}


.nav-mint-btn {
    color: #fff;
    margin-left: 24px;
    padding: 16px 24px;
    font-size: 16px;
    border: none;
    border-radius: 10px;
    background-color: #1946bd;
    text-decoration: none;
    cursor: pointer;
    transition: 0.3s;
}
.nav-mint-btn:hover {
    background-color: #0d6efd;
    color: #fff;
}

/**/
.nav-social1 {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 30px;
}
.social-icon-link1 {
    margin: 0 24px;
    color: #3a7fed;
    font-size: 32px;
}
.nav-social1 .discord {
    margin-bottom: 4px;
}
  


/* Responsive */
@media screen and (max-width: 1024px) {
    .navbar { 
        height: 90px;
    }

    .navbar-container {
        display: none;
    }

    .navbar-container-mobile {
        display: block;
    }
    
    /* .NavbarItems {
      position: relative;
    } */
  
    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 90%;
        right: 0;
        height: auto;
        position: absolute;
        top: 0;

        left: -150%;
        opacity: 1;
        transition: all 0.5s ease;
        color: #1946bd;
    }

    .nav-menu.active {
        background: #fff; 
        left: auto;
        width: 90%;
        height: 100vh;
        margin: 0;
        opacity: 1;
        z-index: 1;    
        outline: 0;
        position: fixed;
        box-shadow: 0 0 100px 100px rgba(0,0,0,0.5);
        transition: all 0.5s ease;
   
    }
    .nav-mobile-header {
        width: 100%;
        display: block;
    }
    .nav-mobile-header > i {
        padding: 24px;
        color: #1c4a7d;
    }
    .nav-mobile-header > img {
        width: 130px;
        display: flex;
        margin: 12px auto 0;
    }
    .nav-mobile-header > h2 {
        display: flex;
        margin: 24px auto;
        font-size: 28px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #1c4a7d;
        justify-content: center;
    }
    .nav-item {   
        /* margin: 0 32px; */
        padding: 8px 0;
        border-top: 2px solid #d5d5d5;
        height: auto;

    }
    .nav-item > li > a{   
        padding: 0;
    }
    .nav-links {
        text-align: left;
        width: 90%; 
        padding: 8px 0;
        display: table;
        color: #484848;
        font-weight: bold;
    }
    .dropdown-button {
        padding: 8px 0;
        color: #1c4a7d;
    }
    .navbar-logo {
        margin: 0;
    }
    .navbar-logo > img{
        width: 60px;
        margin: 0 24px;
    }
    .nav-mint-btn {
        width: 100%;
        margin: 12px 0;
        text-align: center;
    }
    .nav-media {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
        color: white;
    }
    
    .nav-social1 {
        padding-right: 0;
    }

    .social-icon-link1 {
        margin: 0 12px;
        color: #3a7fed;
        font-size: 24px;
    }

    .menu-icon {
        display: block;
        font-size: 24px;
        cursor: pointer;
        color: #3a7fed;
        margin: 0 12px;
    }
    .fa-bars {
        color: #3a7fed;
        font-size: 24px;
    }
    .fa-times {
        color: #3a7fed;
        font-size: 24px;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        background: #1946bd;
        text-decoration: none;
        color: #fff;
        font-size: 1.5rem;
    }

    .nav-links-mobile:hover {
        background: #fff;
        color: #1946bd;
        transition: 250ms;
    }
    .nav-item-dropdown {
        display: flex;
        flex-direction: column;
        margin: 0 32px;
        padding: 8px 0;
        border-top: 2px solid #d5d5d5;
        height: auto;
        font-size: 16px;
    }
    .dropdown-button {
        border: none;
    }
    .dropdown-content {
        display: flex;
        flex-direction: column;
        /* border-left: 2px solid #d5d5d5; */
    }
    .dropdown-content > a {
        padding: 8px 0;
        text-decoration: none;
        color: #1c4a7d;
        margin-left: 24px;
    }
    .mb-dd-coming-soon {
        padding: 3px 8px;
        background-color: #ff0505;
        border-radius: 5px;
        font-family: Calibri;
        font-size: 10px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        text-align: right;
    }
    .offcanvas-end {
        width: 90% !important;
    }
    .offcanvas-content {
        padding: 0 1rem;
    }
    .offcanvas-line {
        border: solid 1px #e6e6e6;
    }
    .offcanvas-content .nav-item {
        padding: 1rem 0;
    }
    .offcanvas-menu-title {
        padding: 1rem 0;
        font-family: Cunia;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #8ea6c0;
    }
    .offcanvas-menu-title.active {
        color: #1c4a7d;
    }
    .offcanvas-menu-title.comingsoon {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #c3c3c3;
    }
    .offcanvas-content a {
        text-decoration: none;
    }
    .offcanvas-content .accordion-body {
        padding: unset;
    }
    .offcanvas-content .accordion-button {
        padding: 1rem 0;
        font-family: Cunia;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #8ea6c0;
    }
    .offcanvas-content .accordion-button:not(.collapsed) {
        color: #8ea6c0;
        background-color: #fff;
        box-shadow: unset;
    }
    .offcanvas-content .accordion-button:focus {
        border-color: #fff;
        box-shadow: unset;
    }
    .offcanvas-content .accordion-button::after {
        width: 1rem;
        height: 1rem;
        background-size: 1rem;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%238ea6c0'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }
    .offcanvas-menu-dropdown-wrapper {
        position: relative;
    }
    .dropdown-wrapper-left {
        opacity: 0.45;
        border: solid 1px #7bc1ff;
        position: absolute;
        top: 7%;
        bottom: 5%;
    }
    .inner-wrapper {
        padding: 0 0 0 1rem;
    }
    .inner-wrapper a {
        text-decoration: none;
    }
    .inner-wrapper .offcanvas-menu-title {
        font-size: 14px;
    }
    .comingsoon .coming-soon {
        padding: 3px 8px;
        background-color: #ff0505;
        border-radius: 5px;
        font-family: Calibri;
        font-size: 10px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
    }
}
