.music-section {
    text-align: center;
    padding: 0 30%;
}
.music-section .description {
    font-family: Calibri;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: center;
    color: #1c4a7d;
    padding: 2rem 0 0;
}
.music {
    border-radius: 12px;
    width: 400px;
    height: 400px;
}
.space-divider {
    margin: 10rem 0;
}

@media screen and (max-width: 820px) {

}

@media screen and (max-width: 576px) {
    .music-section {
        padding: 0 1.5rem;
    }
    .music {
        width: 100%;
        height: 300px;
    }
    .music-section .description {
        font-size: 16px;
    }
    .space-divider {
        margin: 5rem 0;
    }
}