.hide {
    display: none;
}

.wrap-merch {
    width: 100%;
    height: auto;
    margin: -13rem 0 0;
    padding: 0;
    background-image: linear-gradient(to bottom, #fff, #a5dafd 49%, #fff);
}

.merch-header-container {
    width: 75%;
    max-width: 1440px;
    margin: 13rem auto 0;
    display: flex;
    align-items: center;
}
.merch-header {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    margin: 0;
    padding-top: 40px;
    /* align-items: center; */
}
.merch-header > h2 {
    font-family: Cunia;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    padding-right: 30px;
}
.merch-header-member {  
    color: #8ea6c0;
}
.merch-header-merch {  
    color: #1c4a7d;
}
.merch-header > i {
    padding-right: 30px;
    font-size: 16px;  
    color: #1c4a7d; 
}
.view-cart-items {
    width: 300px;
    padding-top: 20px;
}
.view-cart-items > a {
    text-decoration: none;
    cursor: pointer;
    color: #4d4d4d;
}
.view-cart-items > a:hover {
    color: #4d4d4d;
}
.view-cart-items > a > p {
    margin: 0 12px 5px;
    font-family: 'Calibri';
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4d4d4d;
    text-decoration: none;
}
.view-all-cart-link {
    display: flex;
    flex-direction: row;
    align-items: center;
}
/* p1 */
.merch-content-p1 {
    width: 75%;
    max-width: 1440px;
    height: auto;
    margin: 60px auto ;
    text-align: center;
}
.m-c-p1-item-1 > h1 {
    font-size: 60px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1c4a7d;
}
.m-c-p1-item-2 {
    margin: 70px auto 0;
}
.m-c-p1-item-2 > img {
    width: 100px;
    height: 100px;
    justify-content: center;
}
.m-c-p1-item-2 > p {
    margin-top: 24px;
    font-family: Calibri;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    text-align: center;
    color: #1c4a7d;
}
.m-c-p1-item-3 {
    margin: 70px auto 0;
    display: flex;
    flex-direction: column;
}
.m-c-p1-item-3 > button {
    margin: 0 auto 30px;
    width: 300px;
    color: #fff;
    padding: 16px 40px;
    font-size: 14px;
    border: none;
    border-radius: 10px;
    background-color: #1946bd;
    text-decoration: none;
    cursor: pointer;
    transition: 0.3s;
}
.m-c-p1-item-3 > button:hover{
    background-color: #0d6efd;
}
/* p2 */
.merch-content-p2 {
    width: 75%;
    max-width: 1440px;
    height: auto;
    margin: 60px auto;
    text-align: center;
}
.m-c-p2-item-1 > h1 {
    font-size: 60px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1c4a7d;
}
.m-c-p2-item-2 {
    margin: 100px auto 0;
}
.m-c-p2-item-2 > img {
    width: 140px;
    height: 140px;
    justify-content: center;
    opacity: 0.5;
}
.merch-requirement-1 {
    margin-top: 36px;
    font-family: 'Calibri';
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    text-align: center;
    color: #1c4a7d;
}
.merch-requirement-2 {
    font-family: 'Calibri';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
    color: #8ea6c0;
    margin-top: 16px;
}
.m-c-p2-item-3 {
    margin: 50px auto 0;
}
.m-c-p2-item-3 > a {
    color: #fff;
    padding: 16px 40px;
    font-size: 14px;
    border: none;
    border-radius: 10px;
    background-color: #1946bd;
    text-decoration: none;
    cursor: pointer;
    transition: 0.3s;
}
.m-c-p2-item-3 > a:hover{
    color: #fff !important;
    background-color: #0d6efd;
}

/***** Merch and History Tab *****/
.nav-tabs {
    border-bottom: 0px solid #fff !important;
}
.merch-content-display > ul {
    justify-content: center;
}
.merch-content-display > ul > li > .nav-link  {
    width: 400px;
    height: 40px;
    font-family: 'Calibri';
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding: 9px 153px 8px 155px;
    color: #1946bd;
    border-radius: 0px;
    border: solid 1px #1946bd;
    background-color: transparent;
}
.merch-content-display > ul > li > .nav-link.active  {
    color: #fff;
    border: solid 1px #1946bd;
    background-color: #1946bd;
}
.merch-content-display > ul > li > .nav-link:hover  {
    /* color: #fff; */
    border: solid 1px #1946bd;
}
.nav-item #uncontrolled-tab-example-tab-merch {
    border-radius: 8px 0px 0px 8px;
}
.nav-item #uncontrolled-tab-example-tab-history {
    border-radius: 0px 8px 8px 0px;
}


/* Show Merch */
.merch-content-display {
    width: 75%;
    max-width: 1440px;
    height: auto;
    margin: 60px auto;
    text-align: center;
}
.m-c-display-header > h3 {
    margin-top: 50px;
    font-family: 'Cunia';
    font-size: 35px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1c4a7d;
}
.m-c-display-header > p {
    margin-top: 16px;
    font-family: 'Calibri';
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1c4a7d;
}
.m-c-display-items {
    margin: 60px auto;
    width: 90%;
}
.merch-items {
    /* display: flex; */
    /* flex-direction: row; */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
}
.merch-product-item {
    width: 100%;
    margin: 0 10px 20px 10px;
    border-radius: 8px;
    list-style-type: none;
    background-color: #f8f8f8;
}
.merch-item-pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
    border-radius: 5px 5px 0 0;
    cursor: pointer;
}
.fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
}
.merch-item-img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
}
.merch-item-img:hover {
    transform: scale(1.1);
}.merch-item-info {
    margin: 20px;
}
.merch-item-title {
    font-family: 'Calibri';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4d4d4d;
}
.merch-item-price {
    font-family: 'Calibri';
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4d4d4d;
}
.merch-addtocart {
    margin: 24px 20px;
}
.merch-addtocart > button{
    color: #fff;
    width: 100%;
    padding: 16px 40px;
    font-size: 14px;
    border: none;
    border-radius: 10px;
    background-color: #1946bd;
    text-decoration: none;
    cursor: pointer;
    transition: 0.3s;
}
.merch-addtocart > button:hover{
    color: #fff !important;
    background-color: #0d6efd;
}
.merch-product-coming {
    display: flex;
    align-content: stretch;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin: 0 10px 20px 10px;
    list-style-type: none;
    background-color: #fff;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}
.merch-item-pic-coming {
    width: 100%;
    padding: 110px;
}
.merch-item-pic-coming > img {
    width: 140px;
    height: 140px;
}
.merch-item-coming {
    background-color: #f8f8f8;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.merch-coming-title {
    font-family: 'Calibri';
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4d4d4d;
}
/* Add to cart Modal */
.modal-content {
    width: 500px !important;
    height: auto;
}
.modal-header {
    border-bottom: none !important;
}
.modal-title > h4 {
    padding-left: 170px;
    font-family: 'Calibri';
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4d4d4d;
}
#Modal-body-1 {
    width: 100%;
    border-bottom: 1px solid #dee2e6;
}
.modal-body > li {
    background-color: #fff;
    margin: 0;
}
.modal-body > li > figure {
    width: 300px;
    height: 180px;
    padding: 0;
    margin: 0 auto;
}
.modal-body > li > figure > img {
    width: 300px;
    height: auto;
}
.modal-body Button {
    display: none;
}
.item-count-container {
    text-align: center;
}
.item-decrement-btn {
    width: 25px;  
    height: 25px;
    border: none;
    border-radius: 20px;
    color: #fff;
    background-color: #afafaf;
}
.item-decrement-btn.active {
    background-color: #1946bd; 
}
.item-increment-btn {
    width: 25px;  
    height: 25px;
    border: none;
    border-radius: 20px;
    color: #fff;
    background-color: #afafaf;
}
.item-increment-btn.active {
    background-color: #1946bd; 
}
.item-input-count {
    width: 70px;
    margin: 0 20px;
    text-align: center;
    font-family: 'Calibri';
    font-size: 22px;
    font-weight: bold;
    color: #4d4d4d;
    border: none;
}

/* No stock layout */
.nostock-product-item {
    width: 100%;
    margin: 0 10px 20px 10px;
    border-radius: 8px;
    list-style-type: none;
    background-color: #f8f8f8;
}
.nostock-item-pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
    border-radius: 5px 5px 0 0;
    cursor: pointer;
}
.nostock-img {
    animation-name: fade-img;
    animation-duration: 2s;
}
.nostock-item-img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
    opacity: 0.5;
}
.nostock-item-img:hover {
    transform: scale(1.1);
}
.nostock-item-info {
    margin: 20px 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.nostock-item-info-1 {
    padding-left: 30px;
    width: 100%;
}
.nostock-item-title {
    font-family: 'Calibri';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4d4d4d;
}
.nostock-item-price {
    font-family: 'Calibri';
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4d4d4d;
    margin: 0;
}
.nostock-logo {
    width: 50px;
    height: 50px;
    margin-left: auto;
}
.nostock-btn {
    margin: 24px 20px ;
}
.nostock-btn > button {
    color: #fff;
    width: 100%;
    padding: 16px 40px;
    font-size: 14px;
    border: none;
    border-radius: 10px;
    background-color: #ff4c4c;;
    text-decoration: none;
    cursor: pointer;
    transition: 0.3s;
}
.nostock-btn > button:hover, .nostock-btn > button:focus, .nostock-btn > button:active  {
    background-color: #ff4c4c;
    border: none !important;
    outline: none !important;
    box-shadow: none;
}

.modal-footer {
    border-top: none !important;
    justify-content: center !important;
}
.modal-footer > button {
    font-size: 13px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    padding: 15px 40px;
    border: none;
    border-radius: 8px;
    color: #fff;
    background-color: #1946bd;
    transition: 0.3s;
}
.modal-footer > button:hover {
    color: #fff;
    background-color: #0d6efd;
}

/****** History *******/
.merch-history-container {
    width: 800px;
    height: auto;
    margin: 40px auto;
    padding: 0;
    border-radius: 8px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.merch-history-header {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    border-bottom: solid 1px #e4e4e4;
}
.history-order {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 20px;
    font-weight: normal;
    letter-spacing: normal;
}
.history-order > h3 {
    font-family: 'Calibri';
    font-size: 14px;
    color: #afafaf;
    margin: 0;
}
.history-order > p {
    font-family: 'Calibri';
    font-size: 14px;
    margin: 0;
    padding: 0 20px;
    letter-spacing: normal;
    color: #1c4a7d;
}
.history-orderstatus {
    width: 130px;
    height: 30px;
    margin-left: auto;
    padding: 5px 0px;
    border-radius: 0px 8px 0px 8px;
}
.history-orderstatus.pending {
    background-color: #d3d3d3;
}
.history-orderstatus.payment_success {
    background-color: #495057;
}
.history-orderstatus.shipped {
    background-color: #3b7ddd;
}
.history-orderstatus.completed {
    background-color: #87b64f;
}
.history-orderstatus.payment_failed {
    background-color: #ff4c4c;
}
#success {
    background-color: #87b64f;
}
#failed {
    background-color: #ff4c4c;
}
.history-orderstatus > p {
    font-family: 'Calibri';
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    color: #fff;
}
.hist-payment-method {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    border-bottom: solid 1px #e4e4e4;
}.payment-method-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 20px;
}
.payment-method-item > p {
    font-family: 'Calibri';
    font-size: 14px;
    font-weight: bold;
    color: #414141;
    margin: 0;
}
.hist-details {
    padding: 20px;
}
.hist-list-items-1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between
}
.hist-merch-col-1 {
    font-family: 'Calibri';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4d4d4d;
}
.hist-merch-col-2 {
    font-family: 'Calibri';
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1c4a7d;
}
.hist-merch-col-3 {
    width: 320px;
    font-family: 'Calibri';
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #1c4a7d;
    margin-bottom: 0;
}
.hist-list-items-2 {
    text-align: left;
}



/****** Responsive *******/

@media screen and (min-width: 992px) {
    .modal-lg, .modal-xl {
        max-width: 500px !important;
    }
}

@media screen and (max-width: 1540px) {
    .merch-item-pic-coming {
        width: 100%;
        padding: 70px;
    }
    .merch-item-coming {
        height: 55px;
    }
    .m-c-display-items {
        width: 100%;
    }
}

@media screen and (max-width: 1080px) {
    .nav {
        flex-wrap: nowrap !important;
    }
    .nav-item {
        border: none;
    }
    .merch-content-display {
        width: 90%;
    }
    .merch-content-display > ul > li > .nav-link {
        width: 100%;
    }

    .m-c-display-header > h3 {
        font-size: 30px;
    }
    .m-c-display-header > p {
        font-size: 16px;
    }
    .merch-content-display {
        width: 90%;
    }
    .merch-history-container {
        width: 100%;
    }
    .merch-item-pic-coming {
        padding: 40px 0;
    }
    
    .merch-items-1 > ul {
        padding-left: 0 !important;
    }
    .merch-addtocart > button {
        padding: 12px 0;
    }
    
    .nostock-item-info-1 {
        padding-left: 0;
    }
    .nostock-btn > button {
        padding: 12px 0;
    }

}

@media screen and (max-width: 576px) {
    .m-c-p1-item-1 > h1 {  
        font-size: 24px;
    }
    .m-c-p1-item-2 > p {
        font-size: 14px;
    }
    .m-c-p1-item-3 > button {
        font-size: 13px;
    }

    .merch-items {
        flex-direction: column;
        padding: 0;
        grid-template-columns: repeat(1, 1fr);
    }
    .merch-product-item {
        margin: 0 0 24px;
    }
    .merch-product-coming {
        margin: 0;
    }
    .merch-content-display > ul > li > .nav-link {
        width: 170px;
        padding: 0;
    }
    .merch-content-display {
        width: 85%;
    }
    .hist-merch-col-3 {
        width: 100%;
    }
    .modal-title > h4 {
        margin: 0 auto;
        padding: 12px 0 0 180px;
    }

    .modal-header .btn-close {
        padding: 0 12px 0;
        margin: 0;
        font-size: 12px;
    }
    .modal-body {
        padding: 0 !important;
    }
    .modal-dialog-centered {
        justify-content: center;
    }
    .merch-header-container {
        width: 90%;
    }
    .merch-header {
        display: none;
    }
    .view-cart-items {
        width: 100%;
    }
    .view-all-cart-link {
        justify-content: flex-end;
    }
    .modal-title > h4 {
        margin: 0 auto;
        padding: 12px 0 0 100px;
    }
    
    .nostock-product-item {
        margin: 20px 0;
    }
    
}
