.wrap-marquee {
    width: 100%;
}

.marquee-container {
    overflow-x: hidden !important;
    display: flex !important;
    flex-direction: row !important;
    position: relative;
    width: 100%;

    --pause-on-hover:running; 
    --pause-on-click:running;
}
.marquee-container:hover div {
    animation-play-state: var(--pause-on-hover);
}
.marquee-container:active div {
    animation-play-state: var(--pause-on-click);
}
#marquee {
    padding: 0 0 6rem;
}
.marquee {
    flex: 0 0 auto;
    min-width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    animation: scroll var(--duration) linear var(--delay) var(--iteration-count);
    animation-play-state: var(--play);
    animation-delay: var(--delay);
    animation-direction: var(--direction);

    --play:running; 
    --direction:normal; 
    --duration:159.806s; 
    --delay:0s; 
    --iteration-count:infinite;
}

.akc-pet .horizontal-scroll > img {
    height: 250px;
}


@keyframes scroll {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}
.horizontal-scroll > img {
    height: 200px;
}

#akfc_raffle #marquee {
    padding-top: 6rem;
}
#akfc .horizontal-scroll > img {
    height: 250px;
}

@media screen and (max-width: 576px) {
    .akc-pet {
        padding: 2rem 0 !important;
    }
    #storyline {
        padding-bottom: 5rem;
    }
    #marquee {
        padding-bottom: 5rem;
    }
    .homepage .horizontal-scroll > img {
        height: 100px;
    }
    .akc-pet .horizontal-scroll > img {
        height: 100px;
    }
    #akfc_raffle #marquee {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .akfc .horizontal-scroll > img {
        height: 100px;
    }
}