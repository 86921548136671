.wrap-merch-payment {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0 0 60px;
    background-color: #f8f8f8;
}

.merch-payment-container {
    width: 900px;
    margin: 50px auto 0;
}
.total-cart-items {
    /* padding: 12px 0; */
    border-bottom: solid 1px #e4e4e4;
}
.total-cart-items > p {
    font-family: 'Calibri';
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #afafaf;
    padding-left: 150px;
}

.merch-payment-content {
    width: 100%;
}
.merch-payment-list {
    display: flex;
    flex-direction: row;
    padding: 20px 0;
    align-items: center;
    border-top: solid 1px #e4e4e4;
    border-bottom: solid 1px #e4e4e4;
}
.merch-payment-item-img {
    width: 90px;
    margin-right: 24px;
}
.merch-payment-item-name {
    font-family: 'Calibri';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4d4d4d;
}
.merch-payment-item-price, .merch-payment-quantity {
    font-family: 'Calibri';
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4d4d4d;
}
.merch-payment-quantity {
    margin-left: auto;
}

/*  */
.merch-payment-details {
    width: 100%;
    margin: 24px 0;
}
.merch-delivery-header {
    margin-top: 12px;
    font-family: 'Calibri';
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4d4d4d;
}
.merch-delivery-container {
    margin-top: 24px;
}
.merch-del-details {
    margin-bottom: 12px;
    padding: 15px 20px;
    border-radius: 8px;
    background-color: #fff;
}
.merch-del-details-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.merch-del-details-text {
    font-family: 'Calibri';
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1c4a7d;
    margin: 0;
}
.form-select  {
    font-family: 'Calibri';
    font-size: 14px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    width: 300px !important;
    cursor: pointer;
}
.form-select > option  {
    font-family: 'Calibri';
    font-size: 14px !important;
    font-weight: normal;
    cursor: pointer;
    padding: 4px 0;
}
.merch-del-details-form > input[type=text], select {
    font-family: 'Calibri';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #000;
    width: 80%;
    height: auto;
    padding: 4px 16px;
    border: none !important;
    resize: vertical
}
input[type=text]:focus {
    color: #000;
    outline: none;
}   

.merch-shipping-method {
    /* display: none; */
    display: flex;
    justify-content: space-between;
}
.merch-ship-button {
    width: 420px;
    height: auto;
    color: #afafaf;
    background-color: #fff;  
    border: 1px solid #fff;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.3s;
}
.merch-ship-button:hover {  
    color:#1946bd;
    border: 1px solid #1946bd;
}
#ship-metd-btn:focus {   
    color:#1946bd;
    border: 1px solid #1946bd;
}
.merch-ship-method-1 {
    display: flex;
    flex-direction: row;
    padding: 20px 30px;
    text-align: left;
    
}
.ship-method-row-1-1 {
    font-family: 'Calibri';
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 12px 0 0;
}
.ship-method-row-1-2 {
    font-family: 'Calibri';
    font-size: 12px;
    margin: 6px 0 0;  
    color: #afafaf;
}
.ship-method-row-2 {
    margin-left: auto;
}
.ship-method-row-1-3 {
    font-family: 'Calibri';
    font-size: 12px;  
    color: #000;
    margin: 12px 0 0;
}
.ship-method-row-1-4 {
    font-family: 'Calibri';
    font-size: 16px;
    font-weight: bold; 
    color: #000;
    margin: 10px 0 0;
}
.shipping-icon-1 {
    font-size: 24px;
}
.shipping-icon-2 {
    font-size: 20px;
}

/* Payment method */
.merch-payment-summary {
    width: 900px;
    background-color: #fff;
    margin: 50px auto 0;
    padding: 20px;
}
.m-p-overall-sum {
    display: flex;
    justify-content: space-between;
}
.merch-payment-sum-text {
    font-family: 'Calibri';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4d4d4d;
}
.merch-payment-sum-price {
    font-family: 'Calibri';
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4d4d4d;
}
.merch-payment-final-purc { 
    text-align: center;
    margin: 24px auto ;
}
.merch-payment-final-purc > p {
    font-family: 'Calibri';
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #afafaf;
}
.payment-method-op {
    display: flex;
    justify-content: center;
}
.payment-method-op > button {
    width: 250px;
    height: 45px;
    margin: 0 20px;
    border-radius: 8px;
    font-family: 'Cunia';
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    justify-content: center;
    background-color: #fff;
    display: flex;
    align-items: center;
    transition: 0.3s;
}
.payment-method-op > button:hover {
    box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.15);
    -webkit-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.15);
}
.p-meth-paypal {
    color: #1946bd;
    border: solid 1px #1946bd;
}
.p-meth-paypal > img {
    width: 20px;
    margin-left: 10px;
}
.p-meth-usdt {
    color: #00a478;
    border: solid 1px #00a478;
}
.p-meth-usdt > img {
    width: 20px;
    margin-left: 10px;
}

@media screen and (max-width: 820px) {
    .wrap-merch-payment {
        padding: 0;
    }
    .merch-payment-container {
        width: 90%;
    }
    .merch-ship-button {
        width: 350px;
    }
    .merch-payment-summary {
        width: 90%;
    }
}

@media screen and (max-width: 576px) {
    
    .merchcart-header > h2 {
        font-size: 13px;
        padding-right: 0;
    }
    .merchcart-header > i {
        font-size: 13px;
        padding: 2px 18px;
    }
    .close-merchcart {
        margin-top: 12px;
    }
    .close-merchcart i {
        font-size: 24px;
    }
    .merch-payment-container {
        width: 90%;
        margin: 12px auto;
    }
    .merch-payment-content {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .merch-payment-details {
        margin-top: 30px;
    }
    .merch-del-details {
        padding: 15px 0 15px 15px;
    }
    .merch-shipping-method {
        flex-direction: column;
    }
    .merch-ship-button {
        width: 100%;
        margin-bottom: 12px;
    }
    .merch-payment-summary {
        width: 100%;
    }
    .merch-payment-quantity {
        margin-bottom: 0;
    }
    .total-cart-items > p {
        padding-left: 145px;
    }
}