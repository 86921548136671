.mint-title {
    font-family: Cunia;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1c4a7d;
    /* margin-bottom: 2rem; */
}

#document {
    padding: 0 15%;
}
#document .pictures {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 3rem;
}
#document .description {
    font-family: Calibri;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #1c4a7d;
}
#document .card-body {
    background-color: #f8f8f8;
}
#document .card-title {
    font-family: Calibri;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #4d4d4d;
}
#document .thumbnail {
    /* position: relative;
    overflow: hidden; */
    height: 450px;
    background-repeat: no-repeat;
    background-position: center 10%;
    background-size: cover;
}
#document .thumbnail.a {
    background-image: url('../../images/BG-46.jpg');
}
#document .thumbnail.b {
    background-image: url('../../images/BG-38.jpg');
}
#document .card {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}

#roadmap_ {
    background: url('../../images/BG-45.png');
    background-size: 90%;
    background-position-y: 50%;
    height: 100%;
}
#roadmap_ .roadmap-wrapper {
    padding: 0 15% 10%;
}
#roadmap_ .roadmap-row {
    display: flex;
    min-height: 100px;
    margin-bottom: 4.5rem;
}
#roadmap_ .rm-number {
    position: relative;
    font-family: Cunia;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1946bd;
}
#roadmap_ .description {
    padding: 5px 0 0 5rem;
}
#roadmap_ .rm-number:before {
    position: absolute;
    left: 84px;
    top: 15px;
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background-color: #1946bd;
}
#roadmap_ .col-section:before {
    content: "";
    border-left: 2px dashed #99ccfa;
    height: 560px;
    position: absolute;
    left: 103px;
    top: 30px;
}
#roadmap_ .rm-title {
    font-family: Calibri;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1c4a7d;
    margin-bottom: 1rem;
}
#roadmap_ .rm-content {
    font-family: Calibri;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #1c4a7d;
}
.rounded-circle {
    width: 200px;
    padding: 1rem 0;
}

#akfc_team {
    padding-top: 3rem;
    padding-bottom: 5rem;
    padding-left: 25rem;
    padding-right: 25rem;
}
#akfc_raffle #akfc_team {
    padding-top: 3rem;
    padding-bottom: 5rem;
    padding-left: 15rem;
    padding-right: 15rem;
}
#akfc_team .col {
    text-align: center;
    padding-left: unset;
    padding-right: unset;
}
#akfc_team .alias {
    font-family: Calibri;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #8ea6c0;
}
#akfc_team .twitter-name {
    padding: 0.5rem 0;
    font-family: Calibri;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1c4a7d;
}
#roadmap_ .title30 {
    font-family: Cunia;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1c4a7d;
    margin: 7rem 0 3rem;
}

#mobile_mint {
    display: none;
}

#akfc_raffle .subtitle1 {
    font-family: Calibri;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: center;
    color: #1c4a7d;
    margin-bottom: 2rem;
}

.section-title {
    font-family: Cunia;
    font-size: 50px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1c4a7d;
}

@media only screen and (max-width: 900px) {
    #document {
        padding: 0 5%;
    }
    #document .pictures {
        width: 100%;
    }
    #mint_section {
        display: none;
    }
    #mobile_mint {
        display: block;
        margin-bottom: 5rem;
    }
    .mobile-rm-image {
        background: url('../../images/BG-45-crop.png');
        background-size: cover;
        background-position: center center;
        height: 900px;
        margin-bottom: -25%;
    }
    #roadmap_ .rm-number {
        font-size: 26px;
    }
    #roadmap_ .rm-number:before {
        left: 50px;
        top: 8px;
    }
    #roadmap_ .col-section:before {
        left: 69px;
        top: 24px;
    }
    #roadmap_ .description {
        padding: 5px 0 0 3.5rem;
    }
    #roadmap_ .rm-title {
        font-size: 20px;
    }
    #roadmap_ .rm-content {
        font-size: 13px;
    }
    .section-title {
        font-size: 24px;
    }
    #roadmap_ .title30 {
        font-size: 24px;
    }
    #roadmap_ {
        background: unset;
        height: unset;
    }
    #roadmap_ .roadmap-wrapper {
        padding: 0 5%;
    }
    #akfc_raffle #akfc_team {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    #akfc_raffle .rounded-circle {
        width: 120px;
        padding: 1.5rem 0;
    }
    #akfc_raffle .subtitle1 {
        font-size: 16px;
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 587px) {
    .mobile-rm-image {
        height: 650px;
    }
}