.loading-modal  {
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    /* background-color: rgba(0, 0, 0, 0.5); */
}
.loading-modal .modal-content {
    background-color: transparent;
    border: none;
}

.loading-header {
    width: 100%;
    border-radius: 8px !important;
    /* background-color: rgba(0, 0, 0, 0.5); */
    align-items: center;
    justify-content: center !important;
    
}
.loading-header h4 {
    font-family: 'Calibri';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    text-align: center;
    margin: 12px auto;
}

.loading-contents  {
    display: flex;
    align-items: center;
}
.loading-icon {
    color: #7ad866;
    margin-right: 24px;
    border-radius: 50px;
}

@media screen and ( max-width:576px) {
    .loading-header {
        width: 85%;
        margin: 0 auto;
    }
}